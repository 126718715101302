import React from 'react'
import Header from '../components/Header'
import WheelRoulette from '../components/WheelRoulette'
const Home = () => {
    return (
        <div>
           
            <WheelRoulette/>
        </div>
    )
}

export default Home
