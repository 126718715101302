import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Header.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
const Header = () => {
    return (
        <div>
            <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
        <h3 style={{fontFamily: 'Playfair Display, serif', color:"#FF6347", textAlign:"center"}} >
                Recipe Roulette
            </h3>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/recipeList">Recipe List</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
            
        </div>
    )
}

export default Header

