import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { postRating } from '../../services/postRating';
import { useDispatch, useSelector } from "react-redux";
import './CreateRating.css';
const CreateRating = () => {
  const recipeName = useSelector(state => state.recipe);

  const [ratingInfo, setRatingInfo] = useState({
    score: null,
    comment: null,
    raterName: '',
    recipe: recipeName.recipeName // Use the global recipeName from Redux state
  });
  const [ratingSubmitted, setRatingSubmitted] = useState(false)

  const handleInfoChange = (e) => {
    setRatingInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleRatingSubmit = async (e) => {
    e.preventDefault();
    const { score, comment, raterName } = ratingInfo;

    console.log("recipename is : ", recipeName.recipeName)
  
    if (score === null || comment === null) {
      toast.error("Please Fill Out All Required Fields");
    } else {
      try {
        setRatingSubmitted(true)
        console.log(ratingInfo)
        await postRating(ratingInfo);
        toast.success("Rating Posted Successfully!");
      } catch (error) {
        console.error("Error posting rating:", error);
        toast.error("An error occurred while posting the rating");
      }
    }
  };
  useEffect(()=>{
    console.log(recipeName)
  },[])
 
  return (
    <div className="ratingForm">
      <h4>How Did You Like It?</h4>
      <br/>
      {ratingSubmitted ? (
        <div>
          <p style={{color:"green"}}><u><b><i>Rating Submitted Successfully!</i></b></u></p>
        </div>
  
) : (
  <Form onSubmit={handleRatingSubmit}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control type="text" name="raterName" placeholder="Name (Optional)" onChange={handleInfoChange} value={ratingInfo.raterName}/>
    </Form.Group>
    <br />

    <Form.Control className="formDropDown" as="select" value={ratingInfo.score} onChange={handleInfoChange} name="score">
      <option value={0}>Select Score</option>
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
      <option value={4}>4</option>
      <option value={5}>5</option>
    </Form.Control>
    <br />
    <br />

    <Form.Group className="mb-3" controlId="description">
      <Form.Control as="textarea" rows={3} name="comment" placeholder="Share Your Experience" onChange={handleInfoChange} />
    </Form.Group>

    <Button variant="success" type="submit" disabled={ratingSubmitted}>
      Submit
    </Button>
  </Form>
)}


    </div>
  );
};



export default (CreateRating);
