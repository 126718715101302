export async function getRecipe(recipeNumber) {
    const url = "https://recipebackend.darien-c.com/ping"; // Adjust the URL as needed
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Assuming you're sending JSON data
        },
        body: JSON.stringify({
          recipeNumber: recipeNumber,
          // Add other data you want to send in the request body
        }),
      });
  
      if (!response.ok) {
        // Handle the case where the server returns an error
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      // Process the data as needed
  
      return data;
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error fetching data:", error);
    }
  }
  