import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './RecipePage.css';
import ListGroup from 'react-bootstrap/ListGroup';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Button from 'react-bootstrap/Button';
import RatingComponent from './ratings/RatingComponent';
const RecipePage = ({ recipeInfo }) => {
  const lowercaseFirst = (str) => `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
  const [imagePath, setImagePath] = useState('');
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey)
  
    return (
      <Button
      variant="outline-dark"
      style={{margin:"10px"}}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
    
    );
  }
  useEffect(() => {

    const recipeName = recipeInfo.name;
    const formattedName = recipeName.replace(/\s+/g, '');
    const finalString = lowercaseFirst(formattedName);
    const updatedImagePath = `/assets/${finalString}.jpg`;
    setImagePath(updatedImagePath);
  }, [recipeInfo]);

  return (
    <div>
      <div className="recipeContainer">
      <h3 className="recipeHeader"><u>{recipeInfo.name}</u></h3>
      <h6><i>{recipeInfo.description}</i></h6>
      
      <div className="imageContainer animate__animated animate__zoomIn">
        <img src={imagePath} alt={recipeInfo.name} className="recipeImage"/>
      </div>
      <br/>
      <Accordion defaultActiveKey="0" className="accordion-container">
      <Accordion.Item eventKey="0">
       
        <CustomToggle eventKey="0">
        <h3 style={{fontWeight:"700"}}>Ingredients</h3>
        </CustomToggle>

          
          
       
        <Accordion.Body className="accordion-body">
          <ul className="accordion-list">
            {recipeInfo.ingredients.map((ingredient, index) => (

              <ListGroup className="accordion-list-item" key={index}>
              <ListGroup.Item> {ingredient}</ListGroup.Item>
              
            </ListGroup>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
      <CustomToggle eventKey="1">
        <h3 style={{fontWeight:"700"}}>Steps</h3>
        </CustomToggle>

        <Accordion.Body className="accordion-body">
          <ul className="accordion-list">
            {recipeInfo.steps.map((step, index) => (
              <ListGroup className="accordion-list-item" key={index}>
              <ListGroup.Item> {step}</ListGroup.Item>
              
            </ListGroup>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
    <br/>
    <br/>
    <RatingComponent/>
    </div>
  );
};

export default RecipePage;
