// src/reducer.js
const initialState = {
    recipeName: '',
  };
  
  const recipeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_RECIPE_NAME':
        return { ...state, recipeName: action.payload };
      default:
        return state;
    }
  };
  
  export default recipeReducer;