export async function getRecipeNames(){
    const url = "https://recipebackend.darien-c.com/getRecipeNames"
    try{
        const response = await fetch(url,{
            method:"GET",
            headers: {
                "Content-Type": "application/json",
              }      
        })
        const data = response.json()
        if(data){
            return data
        }
        else{
            console.log("Error occured in backend")
        }
    }
    catch{
        console.log("Error occured making request")
    }
}