export async function postRating(ratingInfo) {
    const url = "https://recipebackend.darien-c.com/create/createRating";
    try {
      const response = await fetch(url, {
        method: "POST",  // Fix: Use method instead of type
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          score: ratingInfo.score,
          comment: ratingInfo.comment,
          raterName: ratingInfo.raterName,
          recipe: ratingInfo.recipe,
        }),  // Fix: Convert the body to a JSON string
      });
  
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        console.log("Error in posting rating");
      }
    } catch (error) {
      console.log("Unable to reach server");
    }
  }
  