import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { Provider } from 'react-redux'
  import store from './reactRedux/store'
import RecipeList from './pages/RecipeList';
import Header from './components/Header';
import About from './pages/About';
function App() {
  return (
    <div className="App">
               <Provider store={store}>
               <ToastContainer />
               <Header/>

      <Router>   
      <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/recipeList' element={<RecipeList/>}/>
      <Route path='/about' element={<About/>}/>
      </Routes>
      </Router>
      </Provider>


    </div>
  );
}

export default App;
