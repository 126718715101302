export async function getRecipesByName(recipeName){
    const encodedParam = encodeURIComponent(recipeName);
  
    const url = `https://recipebackend.darien-c.com/recipeName/${encodedParam}`;
  
    try {
      const response = await fetch(url, {
        method: "GET",  // Corrected from type to method
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        console.log("Error occurred fetching ratings");
      }
    } catch (error) {
      console.error("Error occurred making request:", error);
    }

}