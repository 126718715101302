import React from 'react'
import CreateRating from './CreateRating'
import ShowRatings from './ShowRatings'
const RatingComponent = () => {
    return (
        <div>
            <CreateRating/>
            <ShowRatings/>

        </div>
    )
}

export default RatingComponent
