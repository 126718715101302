import React, { useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import './Wheel.css'
import Button from 'react-bootstrap/Button';
import { getRecipe } from '../services/getRecipe';
import RecipePage from './RecipePage';
import { setRecipeName } from '../reactRedux/actions';

import { useDispatch, useSelector } from "react-redux";
const data = [
  { option: "European", style: { backgroundColor: "blue", color: "red", fontFamily: 'Playfair Display, serif'
} },
  { option: "Mexican",style: { backgroundColor: "green", color:"red", fontFamily: 'Playfair Display, serif' } },
  { option: "Asian",style: { backgroundColor: "red", color:"red", fontFamily: 'Playfair Display, serif' } },
  { option: "European" , style: { backgroundColor: "blue", color:"red", fontFamily: 'Playfair Display, serif' } },
  { option: "Mexican",style: { backgroundColor: "green", color:"red", fontFamily: 'Playfair Display, serif' } },
  { option: "Asian",style: { backgroundColor: "red", color:"red", fontFamily: 'Playfair Display, serif' } },
  { option: "European" , style: { backgroundColor: "blue", color:"red", fontFamily: 'Playfair Display, serif' } },
  { option: "Mexican",style: { backgroundColor: "green", color:"red", fontFamily: 'Playfair Display, serif' } },
  { option: "Asian",style: { backgroundColor: "red", color:"red", fontFamily: 'Playfair Display, serif' } },
  { option: "European" , style: { backgroundColor: "blue", color:"red", fontFamily: 'Playfair Display, serif' } },
  { option: "Mexican",style: { backgroundColor: "green", color:"red", fontFamily: 'Playfair Display, serif' } },
  { option: "Asian",style: { backgroundColor: "red", color:"red", fontFamily: 'Playfair Display, serif' } },
];

const WheelRoulette= () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [recipeInfo, setRecipeInfo] = useState(null)
  const [recipeNumber, setRecipeNumber] = useState(null);
  const [blockSpin, setBlockSpin] = useState(false)
  const [hideSpinner, setHideSpinner] = useState(false)
  const [showRecipe, setShowRecipe] = useState(false)
  const centeredContainer = document.querySelector('.centeredContainer');
  const dispatch = useDispatch();

  const handleSpinClick = () => {
    setBlockSpin(true)
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setRecipeNumber(newPrizeNumber);
    setMustSpin(true);

  };
  const handleGetRecipe = async () =>{
    setMustSpin(false);
      
          try {

              const data = await getRecipe(recipeNumber);
              console.log('recipeName is: ',data.name )
              dispatch(setRecipeName(data.name));
              setRecipeInfo(data)
              setTimeout((
                setHideSpinner(true)
              ),1000)
              
              setTimeout(() => {
                if (centeredContainer) {
                    centeredContainer.remove();
                    setShowRecipe(true);
                    
                } else {
                    console.log('Element not found');
                }
            }, 2500);

              // Additional code to handle the data as needed
          } catch (error) {
              console.error(error);
              // Handle any errors that occurred during the fetch
          }
      }

  

  return (
    <div>
<div className={`centeredContainer animate__animated animate__zoomIn${hideSpinner ? 'animate__animated animate__zoomOutUp' : ''}`}>
      <div className="wheelContainer">
        <br/>
      <Wheel
  mustStartSpinning={mustSpin}
  backgroundColors={['#3e3e3e', '#df3428']}
  textColors={['#ffffff']}
  outerBorderColor="white"
  innerBorderColor='black'
  radiusLineColor='white'
  prizeNumber={recipeNumber}
  data={data}
  onStopSpinning={handleGetRecipe}  
/>

      <Button variant="success" onClick={handleSpinClick} disabled={blockSpin}style={{ fontFamily: 'Playfair Display, serif' }}>SPIN</Button>
      </div>
      </div>
      {showRecipe && ( <RecipePage recipeInfo={recipeInfo}/>)}
      </div>

  );
};

export default WheelRoulette
