import React from 'react';
import './About.css';
const About = () => {
  return (
    <div className="aboutContainer">
      <h1>About Us</h1>
      <br/>
      <br/>
      <br/>
      <p>
        Welcome to our website! We are passionate about sharing delicious recipes and helping you discover new and exciting flavors.
      </p>
      <p>
        Our mission is to provide a platform for food enthusiasts to explore a wide variety of recipes, from traditional favorites to innovative creations.
      </p>
      <p>
        Feel free to browse our Recipe List and discover your next culinary adventure!
      </p>

    </div>
  );
}

export default About;
