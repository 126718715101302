import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getRatings } from '../../services/getRatings';
import { Card } from 'react-bootstrap';
import './ShowRatings.css'
const ShowRatings = () => {
    const dispatch = useDispatch();
    const [recipeRatings, setRecipeRatings] = useState([])
    const recipeName = useSelector(state => state.recipe);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await getRatings(recipeName.recipeName);
            setRecipeRatings(data.ratings);
          } catch (error) {
            console.error("Error fetching ratings:", error);
            // Handle the error if necessary
          }
        };
      
        fetchData();
      }, []);
    return (
        <div>
            <hr/>
            <h5 style={{paddingTop:'10px'}}>Ratings</h5>
            
            {recipeRatings.length > 0 ? (
      <div>
        {recipeRatings.map((rating, index) => (
            <div className="reviewCard" key={index}>
          <Card key={index} className="mb-3">
          <Card.Body>
            <Card.Title><small>{rating.score}/5</small></Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
            {rating.raterName ? rating.raterName : 'Anonymous'}
</Card.Subtitle>
            <Card.Text>{rating.comment}</Card.Text>
          </Card.Body>
        </Card>
        </div>
        ))}
      </div>
    ) : (
      <p>No ratings available.</p>
    )}
            
        </div>
    )
}

export default ShowRatings

