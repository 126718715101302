import React, { useState, useEffect } from 'react';
import { getRecipeNames } from '../services/getRecipeNames';
import { ListGroup } from 'react-bootstrap';
import './RecipeList.css';
import RecipePage from '../components/RecipePage';
import { getRecipesByName } from '../services/getRecipesByName';
import { setRecipeName } from '../reactRedux/actions';

import { useDispatch, useSelector } from "react-redux";
const RecipeList = () => {
  const [recipeList, setRecipeList] = useState([]);
  const [showRecipe, setShowRecipe] = useState(false);
  const [recipeArray, setRecipeArray] = useState([]);
  const dispatch = useDispatch();

  const handleShowRecipe = async (recipeName) => {
    try {
      const data = await getRecipesByName(recipeName);
      setRecipeArray(data[0]);
      console.log(data)
      dispatch(setRecipeName(data[0]['name']));
      setShowRecipe(true)
      
      
    } catch (error) {
      console.error('Error fetching recipe by name:', error);
      // Handle the error as needed (e.g., show an error message)
    }
  };

  useEffect(() => {
    const fetchRecipeNames = async () => {
      try {
        const data = await getRecipeNames();
        setRecipeList(data);
      } catch (error) {
        console.error('Error fetching recipe names:', error);
        // Handle the error as needed (e.g., show an error message)
      }
    };

    fetchRecipeNames();

  }, []);

  return (
    <div>
      {showRecipe ? (
        <RecipePage recipeInfo={recipeArray} />
      ) : (
        <>
          <h3>Recipe List</h3>
          <br />
          <div className="recipeList">
            {recipeList && recipeList.map((recipeName, index) => (
              <ListGroup
                className="recipe-list-item"
                key={index}
                onClick={() => {
                  handleShowRecipe(recipeName);
                }}
              >
                <ListGroup.Item>{recipeName}</ListGroup.Item>
              </ListGroup>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default RecipeList;
